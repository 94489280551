.shade-picker {
  @include h8;
  font-family: $brandon-text;
  text-transform: inherit;
  border-top: 1px solid $color-black;
  @include swap_direction(padding, 10px 0 0);
  p {
    @include swap_direction(padding, 6px 0);
    @include swap_direction(margin, 0);
  }
  &__shades {
    @include clearfix;
    @include swap_direction(padding, 0 0 10px);
    @include swap_direction(margin, 0);
    list-style: none;
  }
  &__shade-item {
    @include swap_direction(padding, 3px);
    @include swap_direction(margin, 3px);
    @include breakpoint(portrait) {
      @include swap_direction(margin, 3px 2px);
    }
    float: $ldirection;
    overflow: visible;
    display: block;
  }
  &__shade {
    @include circle(41px, #999);
    @include transition(all 0.2s ease-in-out);
    display: block;
    border: 1px solid $color-white;
    @include box-shadow($color-white 0 0 1px 1px);
    &.light-shade {
      border: 1px solid $color-light-gray;
      &.selected {
        border: 1px solid $color-white;
      }
    }
    &.selected {
      border: 1px solid $color-white;
      @include box-shadow($color-black 0 0 1px 1px);
    }
    &:hover {
      border: 1px solid $color-white;
      @include box-shadow($color-black 0 0 1px 1px);
    }
    // IE8 does not support box-shadow
    .lt-ie9 & {
      &.selected,
      &:hover {
        border: 1px solid $color-black;
      }
    }
  }
  &__color-name {
    @include h8;
    @include swap_direction(padding, 6px 0 0 0);
    .selectBox-dropdown {
      min-width: 100%;
      border-#{$ldirection}: none;
      border-#{$rdirection}: none;
      .selectBox-label {
        min-width: 100%;
        @include swap_direction(padding, 0);
      }
    }
    .touch & select,
    .touchevents & select {
      min-width: 100%;
      border-#{$ldirection}: none;
      border-#{$rdirection}: none;
      @include swap_direction(padding, 0);
      height: 40px;
    }
  }
  &__selectbox.selectbox {
    @include swap_direction(padding, 0);
  }
}

// Mobile truncated text:
.js-sku-menu-wrapper {
  position: relative;
  select {
    &:focus,
    &:hover {
      color: $color-black;
    }
  }
}

.js-sku-menu-mobile-text {
  position: absolute;
  top: 0;
  #{$rdirection}: 0;
  #{$ldirection}: 0;
  height: 40px;
  border-top: solid 1px $color-black;
  border-bottom: solid 1px $color-black;
  font-family: $brandon-text-medium;
  font-size: 14px;
  letter-spacing: normal;
  background: $color-white url('https://www.bobbibrowncosmetics.ca/media/images/global/icon-dropdown.png') no-repeat center $rdirection 10px;
  line-height: 40px;
  white-space: nowrap;
  pointer-events: none;
  text-transform: none;
  @include breakpoint($medium-up) {
    display: none !important;
  }
}
