.liquid_liner {
  max-width: 1024px;
  @include swap_direction(margin, 0 auto);
  overflow: hidden;
  position: relative;
  .quickshop .product-gallery {
    border-#{$rdirection}: none;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .button--outline,
  .callout-alt .button {
    border: none;
    background: $color-black;
    color: $color-white;
    &:hover {
      background: $color-pink;
      color: $color-white;
    }
  }
  h4 {
    font-family: $brandon-text-bold;
    font-size: 14px;
    text-transform: uppercase;
    @include swap_direction(margin, 0 auto);
    text-align: center;
    position: relative;
    &:before {
      content: '';
      background: $color-black;
      width: 50%;
      height: 2px;
      display: inline-block;
      position: absolute;
      top: 8px;
      #{$ldirection}: 25%;
    }
    .inner {
      display: inline-block;
      position: relative;
      @include swap_direction(padding, 0 7px 0 9px);
    }
  }
  .product_collection_hero {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 472px;
    &__title {
      font-family: 'MillerBanner Light';
      font-size: 60px;
      letter-spacing: 0px;
      color: $color-white;
      line-height: 0.8em;
      text-transform: uppercase;
      @include breakpoint($tablet-down) {
        font-size: 58px;
      }
    }
    &-description_1 {
      font-size: 20px;
      line-height: 1.5em;
      @include swap_direction(padding, 40px 0);
      div {
        padding-top: 40px;
        border-top: 1px solid #000;
      }
    }
    &__body {
      width: 512px;
      @include swap_direction(padding, 150px 0 0);
    }
    &__subtitle {
      font-size: 24px;
      color: $color-white;
      letter-spacing: 3px;
    }
  }
  .product_lwll-product {
    height: 400px;
    padding-top: 180px;
    background-repeat: no-repeat;
    background-position: center 0;
    width: 768px;
    @include swap_direction(margin, 0 auto);
    p.product-collection-product__description {
    }
    .product-collection-product__shop-details {
    }
  }
  .product-collection-product {
    &__callout {
      font-family: 'MillerBanner Light Italic';
      font-size: 22px;
      margin-bottom: 6px;
    }
    h2 {
      display: none;
    }
    p {
      font-size: 20px;
      text-align: center;
      @include swap_direction(padding, 12px);
      width: 768px;
    }
    .button--outline {
      margin-top: 30px;
    }
    .product-collection-product__price {
      font-family: $brandon-text-bold;
      font-size: 18px;
      text-align: center;
    }
  }
  .text-block_lwll-loveit {
    font-size: 16px;
    background-color: #eff0f0;
    @include swap_direction(padding, 20px 40px);
    margin-top: 40px;
    overflow: hidden;
    h4 {
      @include swap_direction(margin, 12px 0 24px);
      .inner {
        background: #eff0f0;
      }
    }
    @include breakpoint($tablet-down) {
    }
    .text-block_body {
      line-height: 1.5em;
    }
    .left-column,
    .right-column {
      width: 50%;
      float: $ldirection;
      div {
        @include swap_direction(padding, 6px 24px);
      }
    }
    .right-column {
      border-#{$ldirection}: 1px solid grey;
    }
  }
  .text-block_lwll-sliderhead {
    @include swap_direction(margin, 60px auto 0);
    h4 .inner {
      background: $color-white;
    }
  }
  .homepage-slide.module .homepage-slide__slide.module .homepage-slide__slide__inner {
    height: 439px;
    @include breakpoint($landscape-up) {
      max-height: initial;
    }
    .homepage-slide__abstract__inner {
      width: initial;
    }
    .homepage-slide__abstract--default-y-pos {
      top: 50%;
    }
  }
  .product_lwll-remover {
    height: 586px;
    background-repeat: no-repeat;
    background-position: 80% center;
    p.product-collection-product__description {
      width: 50%;
      padding-top: 120px;
    }
    .product-collection-product__shop-details {
      width: 50%;
    }
  }
  .product-collection-footer_r {
    min-height: 248px;
    .icon-freeshipping:before {
      content: url('/_onelink_/bobbibrownca/projects/bobbibrownca/enus2frca/images/images/media/export/cms/global/free-shipping_69px.png');
    }
  }
  .product-collection-footer_l,
  .product-collection-footer_r {
    margin-top: 0;
    margin-bottom: 0;
  }
}
