.holiday {
  &.services {
    .product_collection_hero {
      &__content {
        &-item {
          float: none;
          @include breakpoint($medium-up) {
            width: 100%;
            float: left;
          }
        }
      }
    }
  }
}
