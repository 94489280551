.store-locator.store-locator--mobile {
  #map-canvas {
    height: 100%;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    min-height: 331px;
    .store-locator__tooltip {
      /* padding-#{$ldirection}: 10px; */
      height: 147px; /* 133px; */ /* 75px; */
      width: 199px; /* 180px; */

      .store-locator__tooltip-name {
        text-transform: uppercase;
        color: $color-black;
      }
    }
  }
  .search-submit__text {
    display: none;
  }
  .search-submits {
    background: #ff4661;
    position: absolute;
    height: 32px;
    top: 0;
    #{$rdirection}: 0;
  }
  .store-locator__tooltip {
    .column--hours,
    .store-locator__event {
      display: none;
    }
    &-book-appointment {
      font-family: $body-font-bold;
      @include breakpoint($small-down) {
        border: 2px solid $color-black;
        color: $color-black;
        display: inline-block;
        letter-spacing: 0.3em;
        text-align: center;
        @include swap_direction(padding, 8px 5px 7px);
        @include swap_direction(margin, 10px 0px);
        text-transform: uppercase;
        .icon {
          background: transparent url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/common/calendar_store_mob.jpg') no-repeat;
          background-size: 100% auto;
          width: 15px; /* 31px; */
          height: 21px; /* 37px; */
          cursor: pointer;
        }
      }
    }
    &-name {
      font-family: $body-font-bold;
      font-size: 14px;
    }
  }
  .doors_results {
    .store-locator__tooltip {
      .store-locator__tooltip-name {
        font-size: 14px;
        letter-spacing: 3px;
        margin-bottom: 5px;
      }
      .store-locator__tooltip-phone {
        line-height: 2em;
      }
    }
  }
  .store-locator-section {
    .store-locator__event {
      display: none;
    }
  }
  .directions.button {
    color: $color-white;
  }
  fieldset {
    display: inherit;
  }
  .hide-mobile {
    display: none !important;
  }
  .store-locator-section--list_item {
    .store-locator__button-container {
      display: none;
    }
    &.selected .store-locator__button-container {
      display: block;
    }
  }
  .store-locator__button.mobile-list {
    background: url('https://www.bobbibrowncosmetics.ca/media/images/background-images/background-image5.png') 10px 7px no-repeat;
    color: $color-black;
  }
  .cart-caret {
    /* @include arrow(top, null, $color-white, $color-gray, 20px); - borders just don't look right... */
    background: url('https://www.bobbibrowncosmetics.ca/media/images/background-images/background-image2.png') no-repeat 0 0;
    position: absolute;
    top: -11px;
    #{$ldirection}: 45px;
    width: 22px;
    height: 12px;
  }
}

.store-locator {
  a {
    text-decoration: none;
    &.marker {
      color: $color-black;
      letter-spacing: 2px;
    }
  }
  h1.doorname {
    margin-bottom: 10px;
  }
  .hidden,
  .inactive,
  .hide {
    display: none !important;
  }
  fieldset {
    display: inline;
  }
  .store-locator__header {
    font-family: $body-font-bold;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    @include swap_direction(margin, 20px 0);
  }
  &__results-header {
    @include swap_direction(padding, 0 0 10px);
    border-bottom: 1px solid $color-light-gray;
    font-family: $body-font-bold;
    text-align: center;
  }
  .store-locator__info {
    display: none;
    @include swap_direction(margin, 10px 0 10px 0);
    @include swap_direction(padding, 0 5px);
    font-size: 12px;
  }
  .store-locator__info-strong {
    font-family: $body-font-bold;
    font-weight: bold;
  }
  .store-locator__sections {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .store-locator__button {
    text-align: center;
    display: block;
    float: $ldirection;
    width: 49%;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    height: 35px;
    color: $color-black;
    border: 1px solid $color-black;
    font-family: $body-font-bold;
    margin-#{$rdirection}: 1%;
    &:last-child {
      margin-#{$ldirection}: 1%;
      margin-#{$rdirection}: 0;
    }
    &.active {
      background: $color-black;
      color: $color-white;
    }
    .icon {
      margin-top: 2px;
      font-size: 28px;
      margin-#{$ldirection}: 14px;
      float: $ldirection;
    }
  }
  .store-locator__duty-free-tab-link {
    color: $color-black;
  }
  .store-locator-section--list_item {
    @include swap_direction(padding, 15px);
    &:nth-child(even) {
      background: $color-light-brown;
    }
  }
  .store-locator-section--list_item .store-locator__button {
    margin-top: 13px;
    &.active {
      border: none;
      background: transparent;
      color: $color-black;
    }
  }
  .store-locator-section--list_item-section {
    display: table;
    margin-top: 14px;
    font-size: 14px;
    width: 100%;
    @include swap_direction(padding, 15px 0);
    background: $color-white;
    border: 1px solid $base-border-color;
    h4 {
      font-family: $body-font-bold;
      text-transform: uppercase;
    }
  }
  .store-locator-list__hours {
    border-#{$rdirection}: 1px solid $base-border-color;
  }
  .store-locator-list__hours,
  .store-locator-list__event {
    display: table-cell;
    @include swap_direction(padding, 0 15px);
    width: 50%;
  }
  .store-locator__tooltip {
    font-family: $body-font;
    width: 280px;
    position: relative;
    &-book-appointment {
      display: block;
      border-top: 1px solid $color-black;
      /* background-color: $color-white; */
      font-family: $body-font-bold;
      color: $color-black;
      letter-spacing: 0.3em;
      @include swap_direction(padding, 9px 0 0);
      margin-top: 23px;
      text-align: center;
      .icon {
        background: transparent url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/common/calendar_store_mob.jpg') no-repeat;
        background-size: 100% auto;
        width: 19px;
        height: 23px;
        cursor: pointer;
      }
    }
    .store-locator__tooltip-name {
      font-family: $body-font-bold;
      font-size: 12px;
      text-transform: uppercase;
      &.popup {
        color: $color-pink;
        text-transform: capitalize;
      }
    }
    .store-locator__tooltip-phone {
      color: $color-pink;
    }
  }
  /* .doors_results {
    .store-locator__tooltip {
      &-book-appointment {
        border-top: none;
        background-color: $color-black;
        font-family: $brandon-text-bold;
        color: $color-white;

        .icon {
          background: transparent url("/media/export/cms/appointment_booking/common/calendar_store_mob_inverted.jpg") no-repeat;
        }
      }
    }
  } */
}
/* Here your can change the breakpoint to set the accordion, when screen resolution changed */
@include breakpoint(max-width $tablet) {
  .responsive-tabs--store-locator {
    ul.resp-tabs-list {
      display: none;
      margin-bottom: 15px;
    }
    .resp-tabs-list li {
      font-family: $body-font;
      font-size: 13px;
      text-transform: none;
      text-align: center;
      display: inline-block;
      width: 50%;
      @include swap_direction(padding, 5px 0);
      @include swap_direction(margin, 0);
      list-style: none;
      cursor: pointer;
      float: $ldirection;
    }
    .resp-tabs-container {
      @include swap_direction(padding, 0px);
      background-color: #fff;
      clear: $ldirection;
    }
    h2.resp-accordion {
      cursor: pointer;
      @include swap_direction(padding, 5px);
      display: none;
    }
    .resp-content-active,
    .resp-accordion-active {
      display: block;
    }
    .resp-tab-content {
      border: none;
      @include swap_direction(padding, 0);
      background: $color-white;
    }
    .search-form {
      @include swap_direction(padding, 0);
      position: relative;
      background: transparent;
      input[type='search'] {
        font-family: $body-font;
        width: 100%;
        padding-#{$rdirection}: 50px;
        border-color: $base-border-color;
        @include box-sizing(border-box);
      }
      .search-submit {
        position: absolute;
        #{$rdirection}: 0;
        top: 0;
        &.active {
          background: $color-pink;
        }
      }
      .city {
        width: 92%;
        @include breakpoint($iphone-6-only) {
          width: 85%;
        }
      }
    }
  }
}

.hannah {
  text-align: center;
  @include swap_direction(padding, 40px 0 60px);
  img {
    @include swap_direction(margin, 0);
  }
  .container {
    width: 440px;
    @include swap_direction(margin, 45px auto 0);
  }
  .link {
    border-bottom: 0;
    &.active {
      color: red;
      .icon {
        @include rotate(180deg);
      }
    }
  }
  .meet_bobbi {
    h2.section-header {
      margin-bottom: 23px;
    }
    .info {
      margin-bottom: 39px;
    }
  }
}

.div-read-more {
  display: none;
  div {
    @include swap_direction(padding, 20px 0);
  }
}

.wooster {
  max-width: 776px;
  text-align: center;
  @include swap_direction(margin, 0 auto);
  margin-top: 30px;
}

.chips {
  background: image-url('temp/wr.jpg') no-repeat 0 0;
  height: 227px;
  max-width: 776px;
  text-align: center;
  color: #fff;
  @include swap_direction(margin, 0 auto 80px auto);
  position: relative;
  h3,
  h3 a {
    color: #fff;
    position: absolute;
    bottom: 35px;
    #{$ldirection}: 0;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: $body-font-bold;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

.store-locator--desktop {
  .store-locator-either-or {
    vertical-align: top;
    display: inline-block;
    margin-top: 6px;
    letter-spacing: 3px;
  }
  .store-locator__locate,
  .store-locator__dutyfree {
    color: $color-pink;
    @include swap_direction(margin, 5px 0 0 0);
    display: inline-block;
    vertical-align: top;
    .icon {
      font-size: 24px;
      line-height: 24px;
      vertical-align: middle;
    }
    /*
    .icon-finder {
      float: $ldirection;
      margin-#{$rdirection}: 10px;
      margin-bottom: 7px;
      margin-top: -3px;
      font-size: 24px;
    }
    */
    span.text {
      font-family: $body-font-bold;
      font-size: 14px;
      margin-bottom: 8px;
      letter-spacing: 3px;
      border-bottom: 2px solid $color-pink;
    }
  }
  .store-locator__dutyfree {
    margin: 20px auto 5px;
    display: inline-block;
    float: $rdirection;
    span.text {
      border-bottom-color: $color-pink;
    }
  }
  .store-locator__form-container {
    display: inline-block;
    width: auto;
  }
  .store-locator__links-container {
    @include clearfix;
    width: 100%;
  }
  .store-locator__link-events {
    margin: 20px auto 5px;
    border-bottom: 2px solid $color-pink;
    display: inline-block;
    letter-spacing: 3px;
    float: $ldirection;
  }
  ul.resp-tabs-list {
    display: block;
    .resp-tab-item {
      font-family: $body-font;
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
      width: 50%;
      text-align: center;
    }
  }
  .store-locator__hero {
    font-family: $body-font-bold;
    font-size: 14px;
    @include swap_direction(padding, 45px);
    text-transform: uppercase;
    background: #f4f1ec;
    text-align: center;
    @include swap_direction(margin, 0 0 60px 0);
    .locator_error_messages {
      li {
        color: $color-red;
      }
    }
    select.country {
      display: inline;
    }
    h1,
    h2 {
      letter-spacing: 5px;
    }
    h2 {
      @include t3;
    }
    img {
      @include swap_direction(margin, 0);
      max-width: none;
      height: auto;
      vertical-align: bottom;
    }
    a.selectBox {
      text-align: $ldirection;
      display: inline-block;
      width: 220px;
      float: $ldirection;
      height: 32px;
      line-height: 32px;
      @include swap_direction(margin, 0 10px 0 5px);
      .selectBox-arrow {
        line-height: 2em;
      }
    }
    input.search[type='text'] {
      width: 205px;
    }
    /* placeholder text needs to be black... */
    ::-webkit-input-placeholder {
      /* WebKit browsers */
      color: $color-black;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $color-black;
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $color-black;
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: $color-black;
    }
    #store-search-controls .required_field {
      border: 1px solid $color-red;
    }
  }
  .search-submit {
    @include swap_direction(margin, -3px 0 0 5px);
    background: $color-pink;
    height: 32px;
    line-height: 32px;
    padding: 0 11px;
    .icon-search {
      display: none;
    }
    .search-submit__text {
      display: inline-block;
    }
  }
  .store-locator__search {
    display: inline-block;
    form {
      @include swap_direction(padding, 0);
      width: auto;
    }
  }
  .store-locator__sections {
    display: none;
  }
  .search-form {
    background: transparent;
  }
  .store-locator__main {
    .column {
      width: 60%;
      float: $ldirection;
      @include swap_direction(margin, 0 2%);
      &:last-child {
        width: 30%;
      }
    }
  }
  #map-canvas {
    height: 100%;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    min-height: 450px;
  }
  .store-locator-section--list_item {
    @include swap_direction(padding, 0);
    .store-locator__button-container,
    .store-locator-section,
    .column--hours,
    .store-locator-section--list_item-location {
      display: none;
    }
    .store-locator__tooltip {
      @include swap_direction(padding, 15px);
    }
  }
  .store-locator__tooltip {
    .column {
      width: 50%;
      float: $ldirection;
    }
    &.info_window,
    &.info_window:last-child {
      width: 100%;
      @include swap_direction(margin, 0);
    }
  }
  .store-locator__stores {
    height: 412px;
    overflow-y: scroll;
  }
  .store-locator__event {
    display: none;
    background: $color-black;
    color: $color-white;
    @include swap_direction(padding, 10px 0 0);
    .icon-close {
      display: none;
      color: $color-white;
    }
  }
  .store-locator__event-list {
    display: none;
    color: $color-black;
    background: $color-white;
    border: 1px solid $color-black;
    h5 {
      color: $color-black;
    }
  }
  .responsive-tabs--store-locator {
    .resp-tab-content {
      @include swap_direction(padding, 0);
    }
  }
  .responsive-tabs--store-locator {
    .resp-tab-item {
      text-transform: none;
      margin-bottom: 0;
    }
  }
  .store-locator-section--list_item {
    &:nth-child(odd) {
      background: transparent;
    }
    &.active {
      background: $color-light-brown;
    }
    &:hover {
      cursor: pointer;
      background: $color-light-brown;
    }
  }
  .store-locator-section--list .store-locator__tooltip .store-locator__event {
    display: none;
  }
  .store-locator__directions {
    font-size: 12px;
    background-color: $color-white;
  }
  .store-locator__directions-location {
    @include swap_direction(padding, 15px);
    line-height: 20px;
    background: $color-white;
    border-color: $color-black;
    border-width: 1px 0;
    .store-locator__directions-destination {
      line-height: 40px;
      vertical-align: middle;
      float: $ldirection;
      height: 40px;
      width: 40px;
      text-align: center;
      background: $color-pink;
      color: $color-white;
      margin-#{$rdirection}: 10px;
    }
    .store-locator__directions-eta {
      color: $color-pink;
    }
    .directions-form__errors {
      margin-bottom: 1em;
      color: $color-pink;
    }
    .directions-form__input-wrapper--a {
      margin-bottom: 11px;
    }
    .directions-form__input-wrapper--b {
      margin-bottom: 22px;
    }
    .directions-form__submit {
      @include swap_direction(margin, 0 0 22px 55px);
    }
    .directions-form__input-wrapper {
      .store-locator__directions-destination {
        height: 32px;
        width: 32px;
        line-height: 32px;
        background: $color-black;
      }
      .directions-form__input {
        width: 215px;
      }
    }
    .directions-form__transportation-modes {
      width: 100%;
      text-align: justify;
      .directions-form__transportation-mode-link {
        vertical-align: top;
        display: inline-block;
        zoom: 1;
        color: $color-black;
        &.active {
          color: $color-pink;
        }
      }
      .stretch {
        width: 100%;
        display: inline-block;
        font-size: 0;
        line-height: 0;
      }
    }
    .directions-form__results {
      background-color: $color-white;
    }
    .store-locator__directions-start,
    .store-locator__directions-end {
      background-color: $color-light-brown;
      border-color: $color-black;
      border-style: solid;
      border-width: 1px 0;
    }
    table.adp-placemark {
      display: none;
    }
  }
  .store-locator-section--list_item .store-locator__tooltip {
    font-size: 14px;
  }
  .store-locator-section--list_item {
    .column {
      width: 100%;
      @include swap_direction(margin, 0);
    }
  }
  .store-locator-section--list {
    .store-locator__event {
      font-size: 14px;
      .store-locator__event-label {
        @include swap_direction(padding, 10px);
      }
      .store-locator__event-list__item {
        @include swap_direction(padding, 10px);
        h5 {
          font-family: $body-font-bold;
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
      .icon-close {
        float: $rdirection;
      }
    }
  }
  @include breakpoint(max-width $desktop) {
    .store-locator__locate {
      display: inline-block;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .adp-directions tr {
    .adp-substep:nth-child(1),
    .adp-substep:nth-child(2) {
      width: 10%;
    }
    .adp-substep:nth-child(3) {
      width: 65%;
    }
  }
}

.store-locator__default {
  background: $color-white;
  @include swap_direction(margin, 0);
}

.prue {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  @include swap_direction(padding, 10px 0);
  p {
    font-weight: bold;
    @include swap_direction(margin, 0);
    font-size: 13px;
  }
}
/* Close Button Fix */
#map-canvas img {
  max-width: none;
}

@if $cr22 == true {
  .section-store-locator {
    .homepage_evergreen_services_wrapper {
      .homepage_evergreen_services_body {
        .link {
          @include text-link-1;
        }
      }
    }
  }
}
