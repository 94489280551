.site-email-sms-thank-you {
  max-width: 1024px;
  margin: 30px auto 20px auto;
  padding: 50px 90px 250px;
  background: url(https://www.bobbibrowncosmetics.ca/media/images/account/email-sms-signup-envelope.gif) no-repeat center top;
  @include breakpoint($medium-down) {
    width: 100%;
    padding-bottom: 240px;
  }
  @include breakpoint($small-down) {
    background: none;
    padding: 30px 1%;
    margin-top: 10px;
  }
  &__header {
    width: 700px;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
    @include breakpoint($medium-down) {
      margin-bottom: 20px;
    }
    @include breakpoint($small-down) {
      width: 100%;
      padding: 60px 0 20px 0;
      background: url(https://www.bobbibrowncosmetics.ca/media/images/account/email-sms-signup-envelope-small.gif) no-repeat center top;
      background-size: 95%;
    }
    h1 {
      height: 40px;
      margin-bottom: 18px;
      font-family: $body-font-bold;
      font-size: 3em;
      letter-spacing: 8px;
      line-height: 1em;
      text-transform: uppercase;
      @include breakpoint($small-down) {
        height: auto;
        margin-bottom: 80px;
        font-size: 2em;
        letter-spacing: 4px;
      }
    }
    h2 {
      margin: 0;
      font-size: 1.5em;
      line-height: 1.25em;
      background: #ffffff;
      @include breakpoint($small-down) {
        font-size: 1em;
      }
    }
  }
}

.email-signup__success-text {
  font-family: $body-font-bold;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 16px;
}
