.checkout-confirmation-page {
  .checkout__content {
    padding-right: 0;
    @include breakpoint($medium-up) {
      width: 100%; //59%;
      margin-right: 3%;
    }
  }
  .checkout__sidebar {
    @include hidden;
  }
  .confirmation-panel__content,
  .pre-order-message-display {
    margin: 0;
  }
  .registration-panel {
    width: 100%;
  }
}
