.viewcart {
  .viewcart-buttons-panel {
    .or,
    .gwp-paypal-checkout,
    .paypal-checkout {
      display: none !important;
    }
  }
  .content {
    a {
      &.continue-shopping {
        margin-top: 10px;
      }
      &.gwp_continue-checkout {
        padding: 1em;
      }
    }
  }
}

.enhanced-viewcart {
  .viewcart-wrapper {
    @include breakpoint($medium-up) {
      padding-top: 60px;
    }
  }
}

.checkout__sidebar {
  .cart-header {
    display: none !important;
  }
  .cart-items {
    .cart-item__total {
      @include breakpoint($medium-up) {
        line-height: 15px;
      }
    }
  }
}

.checkout {
  .checkout-pc-content {
    @include breakpoint($medium-up) {
      display: inline-block !important;
    }
  }
}

.pre-order-message-display {
  float: left;
  margin: 10px 0;
  width: 100%;
  font-size: 12px;
  .order-summary__content & {
    font-size: 0.8em;
  }
}

.confirmation-panel {
  &__content {
    .pre-order-message-display {
      font-size: 14px;
    }
  }
}

.order-details-page__content {
  @include breakpoint($medium-down) {
    .cart-item__qty {
      width: 100%;
      &-label {
        display: inline-block;
      }
    }
  }
}
