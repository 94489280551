.skin_cushion {
  max-width: 1024px;
  @include swap_direction(margin, 0 auto);
  padding-top: 60px;
  border-top: 1px solid $color-black;
  overflow: hidden;
  h1 {
    font-family: 'MillerBanner Light';
    font-size: 72px;
    color: $color-white;
    text-align: $ldirection;
    line-height: 1em;
    font-weight: 100;
    letter-spacing: 0px;
    @include breakpoint($tablet-down) {
      font-size: 53px;
    }
  }
  .product_collection_hero {
    @include breakpoint($tablet-down) {
      background-position: -170px;
    }
    &__body {
      color: white;
      width: 40%;
      @include swap_direction(margin, 186px 0 0);
      position: absolute;
      #{$rdirection}: 0;
    }
    &__subtitle {
      font-size: 27px;
      text-transform: uppercase;
      position: absolute;
      top: 18px;
      #{$rdirection}: 0;
    }
    &__html_block {
      font-size: 22px;
      line-height: 1.75em;
      width: 80%;
    }
  }
  .product_collection_hero__content-item:nth-child(1) {
    font-family: $brandon-text-bold;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    position: absolute;
    top: -36px;
    img {
      vertical-align: top;
      padding-#{$rdirection}: 3px;
    }
  }
  .product_collection_hero__content-item:nth-child(4),
  .product_collection_hero__content-item:nth-child(5) {
    width: 50%;
    float: $ldirection;
  }
  .product_collection_hero__content-item:nth-child(6) {
    clear: both;
  }
  .product_compact {
    height: 535px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f8f4eb;
    background-position: center;
    h2 {
      display: none;
    }
    .product-collection-product__description {
      font-size: 20px;
      @include swap_direction(padding, 2% 5% 0 60%);
      @include breakpoint($tablet-down) {
        font-size: 18px;
        @include swap_direction(padding, 2% 5% 0 58%);
      }
    }
    .product-collection-product__shop-details {
      padding-#{$ldirection}: 60%;
      @include breakpoint($tablet-down) {
        padding-#{$ldirection}: 58%;
      }
    }
    img {
      position: absolute;
      top: 0;
      z-index: -1;
    }
    .product-collection-product__price {
      font-family: $brandon-text-bold;
      font-size: 20px;
      width: 180px;
      line-height: 3em;
    }
  }
  .skin_cushion_chart {
    .block_container__content {
      height: 752px;
      position: relative;
      border-bottom: 1px solid $color-black;
    }
    h4 {
      font-family: $brandon-text-bold;
      text-transform: uppercase;
    }
    img {
      display: block;
      margin-#{$ldirection}: 18%;
      @include swap_direction(padding, 81px 0);
      @include breakpoint($tablet-down) {
        margin-#{$ldirection}: 12%;
      }
    }
    .text-block {
      width: 100%;
      @include swap_direction(margin, 21px auto);
      position: absolute;
      top: 0;
      &_body {
        font-size: 18px;
        line-height: 1.75em;
        position: relative;
      }
      .note {
        font-size: 14px;
      }
    }
    .skin-boosting {
      width: 25%;
      position: absolute;
      top: 36px;
      #{$ldirection}: 9%;
      @include breakpoint($tablet-down) {
        width: 27%;
        #{$ldirection}: 4%;
      }
      h4:after {
        content: url('https://www.bobbibrowncosmetics.ca/media/export/cms/collections/skin_cushion/SkinCushion_leader_1.jpg');
        position: absolute;
        top: 15px;
        #{$ldirection}: 169px;
      }
    }
    .fresh-formula {
      width: 25%;
      position: absolute;
      top: 36px;
      #{$rdirection}: 6%;
      @include breakpoint($tablet-down) {
        width: 22%;
        #{$rdirection}: 2%;
      }
      h4:before {
        content: url('https://www.bobbibrowncosmetics.ca/media/export/cms/collections/skin_cushion/SkinCushion_leader_2.jpg');
        position: absolute;
        top: 15px;
        #{$rdirection}: 270px;
        @include breakpoint($tablet-down) {
          #{$rdirection}: 180px;
        }
      }
    }
    .customizable-coverage {
      width: 30%;
      position: absolute;
      top: 490px;
      #{$ldirection}: 9%;
      @include breakpoint($tablet-down) {
        width: 32%;
        top: 508px;
        #{$ldirection}: 4%;
      }
      h4:after {
        content: url('https://www.bobbibrowncosmetics.ca/media/export/cms/collections/skin_cushion/SkinCushion_leader_3.jpg');
        position: absolute;
        top: -136px;
        #{$ldirection}: 270px;
        @include breakpoint($tablet-down) {
          #{$ldirection}: 166px;
        }
      }
    }
    .seamless-application {
      width: 25%;
      position: absolute;
      top: 490px;
      #{$rdirection}: 6%;
      @include breakpoint($tablet-down) {
        width: 40%;
        top: 508px;
        #{$rdirection}: 9%;
      }
      h4:before {
        content: url('https://www.bobbibrowncosmetics.ca/media/export/cms/collections/skin_cushion/SkinCushion_leader_4.jpg');
        position: absolute;
        top: -24px;
        #{$rdirection}: 270px;
        @include breakpoint($tablet-down) {
          top: -24px;
          #{$rdirection}: 56px;
          -moz-transform: scaleX(-1);
          -o-transform: scaleX(-1);
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          filter: FlipH;
          -ms-filter: 'FlipH';
        }
      }
      .note {
        font-size: 14px;
      }
    }
  }
  .product_refill,
  .product_brush {
    font-size: 18px;
    height: 574px;
    background-repeat: no-repeat;
    background-position: center 0;
    @include swap_direction(padding, 256px 9% 0);
    @include breakpoint($tablet-down) {
      @include swap_direction(padding, 208px 9% 0);
    }
    h2 {
      font-family: $brandon-text-bold;
      font-size: 18px;
      line-height: 1.5em;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .product-collection-product__price {
      font-family: $brandon-text-bold;
      font-size: 20px;
      width: 180px;
      line-height: 2em;
    }
  }
  .product_refill {
    background-position: center 12px;
  }
  .product_brush {
    background-position: center 60px;
    background-size: contain;
    border-#{$ldirection}: 1px solid $color-gray-light;
  }
  .block_container.video {
    background: #000;
    color: #fff;
    text-align: center;
    @include swap_direction(padding, 0 39px 39px);
    h4 {
      font-family: 'MillerBanner Light';
      font-size: 30px;
      text-transform: uppercase;
      @include swap_direction(padding, 39px 0);
    }
    .block_container__subtitle {
      font-size: 20px;
      margin-bottom: 24px;
    }
    .video-block {
      &__media {
        width: 100%;
        position: relative;
        @include swap_direction(margin, 0 auto);
        @include breakpoint($large-up) {
          width: 870px;
        }
      }
      &__media--video {
        position: absolute;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        @include swap_direction(margin, 0 auto);
        @include breakpoint($large-up) {
          width: 870px;
          height: 475px;
        }
      }
    }
  }
  .product-collection-footer_l,
  .product-collection-footer_r {
    min-height: 272px;
    margin-top: 0;
    margin-bottom: 0;
    @include breakpoint($tablet-down) {
      border-bottom: 6px solid $color-white;
    }
  }
  .product-collection-footer_r {
    margin-#{$ldirection}: 4px;
    @include breakpoint($tablet-down) {
    }
  }
  .product-collection-footer_r .icon-freeshipping {
    position: relative;
    bottom: 12px;
    &:before {
      content: url('/_onelink_/bobbibrownca/projects/bobbibrownca/enus2frca/images/images/media/export/cms/global/free-shipping_69px.png');
    }
  }
}
