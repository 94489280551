.payment-panel {
  .payment-type {
    .related-media {
      display: block;
      margin-top: 20px;
      img {
        margin-right: 10px;
        display: inline-block;
      }
    }
    label {
      text-transform: uppercase;
    }
  }
  .payment-form {
    label {
      @include hidden;
    }
  }
  .form-container {
    .payment-edit-content__using-paypal {
      display: none !important;
    }
  }
}

.index {
  .checkout {
    .adpl {
      h3 {
        label.has-giftcard {
          @include breakpoint($medium-up) {
            display: none !important;
          }
        }
      }
      &.payment-panel {
        .payment-form {
          .expires-month {
            label {
              display: none !important;
            }
          }
        }
        .change-address {
          &.mobile_hidden {
            @include breakpoint($medium-up) {
              display: inline-block !important;
            }
          }
        }
      }
      .form-item {
        &.address-to-use {
          label {
            display: inline-block !important;
          }
        }
      }
      .gift-options {
        @include breakpoint($medium-up) {
          .card-message-to {
            width: 46%;
            margin-right: 8%;
            float: none !important;
          }
          .card-message-from {
            width: 46%;
            float: none !important;
          }
        }
      }
    }
  }
}
