.extra_glow_collection {
  text-align: center;
  @include breakpoint($medium-up) {
    text-align: left;
  }
  margin-bottom: 20px;
  &.product_collection_hero-v3 .callout-alt .button {
    background-color: $color-black;
    border: 1px solid $color-black;
    &:hover {
      color: $color-white;
      background-color: $color-pink;
      border: 1px solid $color-pink;
    }
  }
  .button--outline {
    color: $color-black;
    border: 1px solid $color-black;
    &:hover {
      color: $color-pink;
      border: 1px solid $color-pink;
    }
  }
  .product_collection_hero {
    font-family: 'Lato Regular';
    background-size: contain;
    background-repeat: no-repeat;
    @include breakpoint($medium-up) {
      color: $color-white;
      height: 424px;
      &__content {
        margin-bottom: 90px;
      }
    }
    @include breakpoint($landscape-up) {
      height: 564px;
    }
    &__body {
      padding-top: 393px;
      @include breakpoint($medium-up) {
        padding: 30px 0 0 57%;
        text-align: center;
        .button--outline {
          color: $color-white;
          border: 1px solid $color-white;
          background-color: rgba(248, 236, 236, 0.5);
          &:hover {
            color: $color-pink;
            border: 1px solid $color-pink;
          }
        }
      }
      @include breakpoint($landscape-up) {
        padding: 124px 0 0 52%;
      }
    }
    &__callout {
      font-size: 26px;
      line-height: 1em;
      padding-bottom: 9px;
    }
    &__title {
      font-family: 'Lato Black';
      .hed_1 {
        font-size: 80px;
        line-height: 0.5em;
      }
      .hed_2 {
        font-size: 86px;
        line-height: 1em;
      }
      .hed_3 {
        font-size: 36px;
      }
      @include breakpoint($landscape-up) {
        .hed_1 {
          font-size: 100px;
        }
        .hed_2 {
          font-size: 110px;
        }
        .hed_3 {
          font-size: 47px;
        }
      }
    }
    &__subtitle {
      font-size: 14px;
      margin: 0 auto 24px auto;
    }
    &__html_block {
      margin: 0 auto 24px auto;
    }
  }
  h2 {
    font-family: $body-font-bold;
    font-size: 14px;
    margin: 20px auto 3px auto;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 20px;
    }
  }
  .product-collection-product {
    &__description {
      padding: 0 36px;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
    &__shop-details {
      margin: 20px 0;
    }
    .price {
      font-family: $body-font-bold;
      font-size: 14px;
      text-transform: uppercase;
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
    @include breakpoint($medium-up) {
      .product-collection-product__price {
        display: none;
      }
    }
  }
  .block_extra_lip_tint {
    .block_container__content {
      position: relative;
    }
    .product-collection-product__shop-details {
      margin: 120px 0 20px;
      @include breakpoint($medium-up) {
        margin: 20px 0;
      }
    }
    .product-image-qs {
      position: absolute;
      bottom: 66px;
      padding: 0 76px;
      @include breakpoint($medium-up) {
        position: static;
        padding: 0;
        margin: 0 20px 60px 0;
        float: left;
        height: 220px;
        display: flex;
        align-items: center;
      }
      @include breakpoint($landscape-up) {
        margin: 0 52px;
      }
    }
    @include breakpoint($medium-up) {
      margin-top: 40px;
      .product-image {
        margin: 72px 0 6px;
        text-align: center;
      }
    }
  }
  .block_extra_skincare_palette {
    background-color: #f8ecec;
    margin: 0 12px;
    padding: 18px;
    .product_chart {
      position: relative;
      img {
        margin: 0 auto;
        display: block;
        @include breakpoint($medium-up) {
          width: 74%;
        }
        @include breakpoint($landscape-up) {
          width: auto;
        }
      }
    }
    .chart_block {
      margin-bottom: 18px;
      @include breakpoint($medium-up) {
        position: absolute;
      }
    }
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: 1.5em;
      .product-collection-product {
        &__product {
          text-align: center;
        }
        &__description {
          padding: 0;
        }
      }
      .skin_salve {
        bottom: 20px;
        left: 1%;
        width: 186px;
        &:after {
          content: url('https://www.bobbibrowncosmetics.ca/media/export/cms/collections/ExtraGlow/SS18_CMS-PC_ExtraGlow_lead1.png');
          position: absolute;
          top: 35px;
        }
      }
      .face_oil {
        bottom: 240px;
        left: 70%;
        &:before {
          content: url('https://www.bobbibrowncosmetics.ca/media/export/cms/collections/ExtraGlow/SS18_CMS-PC_ExtraGlow_lead2.png');
          position: absolute;
          top: 48px;
          left: -112px;
          @include breakpoint($landscape-up) {
            top: 28px;
          }
        }
      }
      .soothing_balm {
        bottom: -7px;
        left: 76%;
        width: 180px;
        &:before {
          content: url('https://www.bobbibrowncosmetics.ca/media/export/cms/collections/ExtraGlow/SS18_CMS-PC_ExtraGlow_lead1.png');
          position: absolute;
          top: 35px;
          left: -112px;
          transform: scaleX(-1);
        }
      }
    }
    @include breakpoint($landscape-up) {
      .skin_salve {
        left: 8%;
      }
      .face_oil {
      }
      .soothing_balm {
        bottom: 20px;
        left: 74%;
        width: 218px;
      }
    }
  }
  @include breakpoint($medium-up) {
    .moisture_balm {
      margin: 36px 0 0;
      overflow: auto;
      .product-collection-product {
        width: 46%;
        float: left;
        &__product-name {
          margin: 0 0 0 36px;
        }
        &__shop-details {
          margin: 20px 0 20px 36px;
        }
      }
      .price {
        margin-left: 36px;
      }
      .product-image-qs {
        float: right;
      }
    }
  }
  @include breakpoint($landscape-up) {
    .moisture_balm {
      .product-collection-product {
        width: 50%;
      }
    }
  }
  .block_highlighting_powder {
    .text-block_link {
      color: $color-black;
      border: 1px solid $color-black;
      line-height: 1;
      display: inline-block;
      padding: 0.75em 1em;
      letter-spacing: 2px;
      &:hover {
        color: $color-pink;
        border: 1px solid $color-pink;
      }
    }
    .product-image_link {
      color: $color-black;
      border: 1px solid $color-black;
      line-height: 1;
      display: inline-block;
      padding: 0.75em 1em;
      letter-spacing: 2px;
    }
    a {
      font-family: $body-font-bold;
      font-size: 14px;
      text-transform: uppercase;
      color: $color-black;
      text-decoration: none;
      &:hover {
        color: $color-pink;
      }
    }
    @include breakpoint($medium-up) {
      overflow: auto;
      margin: -20px;
      .product-image {
        float: left;
      }
      .product-collection-product {
        padding-top: 54px;
      }
    }
    @include breakpoint($landscape-up) {
      margin-top: -72px;
      .product-image {
        margin-right: 180px;
      }
    }
  }
}
