.section-afterpay {
  .page-header {
    background-color: $color-white;
    &.bg-cover {
      max-height: 208px;
      background-size: contain !important;
    }
    &__title {
      letter-spacing: 0;
    }
  }
}

.afterpay-working {
  .page-products-makeup-auto-replenishment {
    .page-header__subtitle {
      .steps {
        display: block;
        @include breakpoint($medium-up) {
          display: flex;
        }
        .step {
          width: 100%;
          height: 150px;
          @include breakpoint($medium-up) {
            width: 33%;
            height: 190px;
          }
          .icon-shipping:before {
            content: url(https://www.bobbibrowncosmetics.ca/media/export/cms/afterpay/ICON_TRUCK.png);
          }
          .icon-schedule:before {
            content: url(https://www.bobbibrowncosmetics.ca/media/export/cms/afterpay/ICON_CALENDAR.png);
          }
          .icon-payment:before {
            content: url(https://www.bobbibrowncosmetics.ca/media/export/cms/afterpay/ICON_CARD.png);
          }
        }
      }
    }
  }
}

.afterpay-faq {
  @include breakpoint($medium-up) {
    width: 100%;
    margin: 0 auto;
    float: $ldirection;
  }
  .customer-service-faqs {
    @include breakpoint($medium-up) {
      max-width: initial;
    }
  }
  .customer-service-header,
  .customer-service-section {
    @include breakpoint($medium-up) {
      float: none;
      margin: 0 auto;
      width: 50%;
    }
  }
  .customer-service-header__title {
    text-align: center;
    letter-spacing: 0;
  }
}
