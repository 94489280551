@if $ab_skin_v2 == false {
  @include breakpoint($small-down) {
    abbr,
    abbr[title] {
      border: none;
      cursor: default;
    }
  }
  // responsive skedgeme iframe
  #skedgemeIframe.ios-device {
    width: 50%;
    @include swap_direction(margin, 0);
    .device-mobile & {
      width: 50%;
    }
  }
  // Collection formatter - main container
  .appt-book {
    position: relative;
    @include swap_direction(padding, 0);
    @include swap_direction(margin, 0 auto 16px);
    max-width: $max-width;
    text-align: center;

    // Back To Link On Mobile
    @include breakpoint($small-down) {
      a.back-to-link,
      a.back-to-link:hover {
        display: block;
        width: auto;
        background: url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/common/back_to_link_arrow.gif') no-repeat 0 3px;
        font-family: $body-font;
        font-size: 12px;
        color: $color-pink;
        text-decoration: none;
        border: none;
        @include swap_direction(margin, 5px 22px);
        padding-#{$ldirection}: 16px;
      }
    }
    &-sections-container {
      position: relative;
      max-width: $max-width;
      @include breakpoint($small-down) {
        @include swap_direction(padding, 0 20px);
        text-align: $ldirection;
        .services & {
          @include swap_direction(padding, 0);
        }
      }
    }
    &-section {
      position: relative;
      width: 100%;
      &-content {
        position: relative;
        width: 100%;
        margin-bottom: 40px;
        @include breakpoint($small-down) {
          margin-bottom: 20px;
          padding-top: 5px;
        }
      }
    }
    .button.disabled,
    input[type='submit'].disabled,
    input[type='button'].disabled {
      color: $color-black;
      background-color: $color-gray;
      cursor: default;
    }
    .basic-responsive {
      position: relative;
      display: block;
      font-size: 18px;
      @include breakpoint($small-down) {
        font-size: 16px;
      }
      a {
        margin-top: 2px;
        @include breakpoint($tablet) {
          margin-top: 0;
        }
      }
    }
    p {
      @include swap_direction(margin, 18px auto 11px);
      letter-spacing: 0.105em;
      @include breakpoint($small-down) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 1.2em;
        letter-spacing: 1px;
      }
    }
    &-link {
      text-transform: uppercase;
      font-family: $body-font-bold;
      padding-bottom: 5px;
      text-decoration: none;
      border-bottom: 1px solid $color-pink;
      font-size: 14px;
      letter-spacing: 0.23em;
      @include breakpoint($small-down) {
        font-size: 12px;
        padding-bottom: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .touch &,
    .touchevents & {
      select {
        background-position: 97% 46%;
        padding-#{$rdirection}: 35px;
      }
    }

    // Appointment Booking page header
    &-page-header {
      position: relative;
      color: $color-black;
      text-decoration: none;
      display: block;
      max-width: $max-width;
      @include swap_direction(margin, 0 auto 16px);
      text-align: center;
      &-bg {
        display: block;
        width: 100%;
        height: auto;
        &.bg-img-mobile {
          display: none;
        }
      }

      // Don't show the pc background image on mobile
      @include breakpoint($small-down) {
        text-align: $ldirection;
        &-bg.bg-img-pc {
          display: none;
        }
        &-bg.bg-img-mobile {
          display: block;
        }
      }
      &-content {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: 58%;

        // resetting position to static to avoid "0"-like height of block
        @include breakpoint($small-down) {
          width: auto;
          position: static;
        }
        .inner {
          position: absolute;
          width: 100%;
          height: auto;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          @include breakpoint($small-down) {
            position: relative;
            top: auto;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            @include swap_direction(margin, 17px 17px 0);
          }
        }
        h2,
        h3,
        h4,
        p {
          text-align: center;
          margin-bottom: 0;
          line-height: 1.2;
          font-size: 20px;
          @include breakpoint($tablet-down) {
            font-size: 18px;
          }
          @include breakpoint($small-down) {
            font-family: $body-font;
            line-height: 1.05em;
            text-align: $ldirection;
          }
        }
        h2 {
          font-family: $body-font-bold;
          font-size: 40px;
          letter-spacing: 9px;
          text-transform: uppercase;
          @include breakpoint($tablet-down) {
            font-size: 32px;
          }
          @include breakpoint($small-down) {
            font-size: 20px;
            letter-spacing: 0.075em;
            //font-family: $brandon-text-medium;
          }
        }
        h3 {
          font-size: 27px;
        }
        h4 {
          font-size: 25px;
          line-height: 1.3;
          @include breakpoint($tablet-down) {
            font-size: 20px;
          }
          @include breakpoint($small-down) {
            font-family: $body-font;
            font-size: 16px;
            padding-top: 12px;
            width: 56%;
          }
        }
        p {
          @include breakpoint($small-down) {
            font-family: $body-font;
            font-size: 16px;
            text-align: center;
          }
        }
        .multi {
          white-space: nowrap;
        }
        .multi--mobile-inline {
          display: inline-block;
          @include breakpoint($tablet) {
            display: block;
          }
        }
        &__tabs {
          list-style-type: none;
          text-align: center;
          font-size: 16px;
          height: 52px;
          letter-spacing: 2px;
          @include breakpoint($small-down) {
            font-size: 12px;
            height: 36px;
          }
          li {
            display: inline-block;
            color: $color-gray;
            border-bottom: 1px solid $color-gray;
            border-#{$rdirection}: 1px solid $color-gray;
            float: $ldirection;
            font-family: $body-font;
            @include swap_direction(padding, 16px 0);
            text-transform: uppercase;
            width: 25%;
            @include breakpoint($small-down) {
              @include swap_direction(padding, 8px 0);
            }
            &.active {
              color: $color-black;
              border-bottom: 5px solid $color-black;
              padding-bottom: 12px;
              @include breakpoint($small-down) {
                border-bottom: 3px solid $color-black;
                padding-bottom: 6px;
              }
            }
            &:last-of-type {
              border-#{$rdirection}: none;
            }
          }
        }
        &__title {
        }
        &__subhead {
        }
      }
    }
    @include breakpoint($small-down) {
      &.counters &-page-header,
      &.services &-page-header,
      &.appointments &-page-header,
      &.confirmation &-page-header {
        text-align: center;
        &-content {
          position: relative;
          @include swap_direction(margin, 20px);
          width: auto;
          height: auto;
          top: auto;
          bottom: auto;
          .inner {
            position: relative;
            width: auto;
            height: auto;
            top: auto;
            #{$ldirection}: auto;
            @include swap_direction(margin, 0 auto);
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
          }
        }
        .section-header {
          &__inner {
            font-size: 23px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    &-content-header {
      margin: 20px auto;
      .service-select & {
        @include breakpoint($small-down) {
          margin-bottom: 0;
          @include swap_direction(padding, 0 20px);
        }
      }
      p {
        @include breakpoint($tablet) {
          font-size: 18px;
        }
      }
      .book-now-bar {
        padding-top: 17px;
        @include breakpoint($small-down) {
          display: none;
        }
      }
    }
    .book-now-bar {
      @include clearfix;
      letter-spacing: 0.23em;
      font-family: $body-font-bold;
      max-width: 372px;
      @include swap_direction(margin, 0 auto);
      .minutes {
        border: 1px solid $color-gray-light-alt;
        background-color: $color-gray-light-alt;
        width: 27.5%;
        @include swap_direction(padding, 7px 10px 10px);
        float: $ldirection;
        display: inline-block;
        text-align: center;
      }
      .button {
        width: 72.5%;
        float: $ldirection;
        display: inline-block;
        letter-spacing: 0.23em;
        padding-bottom: 13px;
        background-color: $color-pink;
        &.disabled {
          background-color: $color-gray;
        }
      }
      @include breakpoint($small-down) {
        width: 100%;
        font-size: 12px;
        max-width: none;
        .minutes {
          width: 28%;
          height: 57px;
          line-height: 45px;
        }
        .button {
          font-size: 12px;
          width: 72%;
          height: 57px;
          padding-top: 9px;
          .sub-text {
            font-family: $body-font;
            font-size: 11px;
            font-style: italic;
            text-transform: none;
            letter-spacing: 0.03em;
          }
        }
      }
    }
    &-location-selector {
      position: relative;
      text-align: center;
      font-size: 18px;
      @include swap_direction(padding, 23px 0 16px);
      @include breakpoint($small-down) {
        padding: 0 0 20px 0;
        font-size: 16px;
      }
      &-copy {
        text-align: center;
        display: block;
        @include breakpoint($small-down) {
          text-align: $ldirection;
          font-family: $body-font-bold;
        }
      }
      .form-container {
        position: relative;
        display: inline-block;
        overflow: hidden;
        margin-top: 18px;
        @include breakpoint($tablet-down) {
          display: block;
          text-align: center;
        }
        @include breakpoint($small-down) {
          width: 100%;
          margin-top: 15px;
        }
      }
      .location-select,
      .location-submit {
        display: block;
        float: $ldirection;
        @include breakpoint($tablet-down) {
          float: none;
          @include swap_direction(margin, 0 auto);
        }
      }
      .location-select {
        width: 506px;
        @include breakpoint($small-down) {
          width: 100%;
          margin-bottom: 21px;
        }
      }
      .location-submit {
        height: auto;
        line-height: 1;
        padding: 11px 11px 10px;
        margin-#{$ldirection}: 20px;
        letter-spacing: 0.23em;
        background-color: $color-pink;
        @include breakpoint($tablet-down) {
          @include swap_direction(margin, 0 auto);
        }
        @include breakpoint($small-down) {
          width: 100%;
          margin-#{$ldirection}: 0;
          font-size: 12px;
        }
      }
    }
    .location-select-section {
    }
    .service-select {
      width: auto;
      @include swap_direction(margin, 0 auto 23px);
      overflow: hidden;
      @include breakpoint($small-down) {
        margin-bottom: -16px;
      }
      .section-header {
        &__inner {
          @include breakpoint($tablet) {
            font-size: 16px;
          }
        }
      }
      .services-container {
        position: relative;
        width: 100%;
        max-width: 984px;
        overflow: hidden;
        @include swap_direction(margin, 40px auto);
        padding-top: 0;
        background: transparent
          url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/services/vert_line_bg.gif')
          repeat-y
          top
          center;
        border-top: 1px solid $color-gray-light-alt;
        border-bottom: 1px solid $color-gray-light-alt;
        @include breakpoint($small-down) {
          background: none;
          border: none;
          margin-top: 0;
          margin-top: 25px;
          @include swap_direction(padding, 0 20px);
        }
      }
      @include breakpoint($small-down) {
        .service:first-child {
          border-top: 1px solid $color-gray-light-alt;
        }
      }
      .service {
        position: relative;
        display: block;
        width: 50%;
        @include swap_direction(padding, 1.9% 1.7% 3.8%);
        min-height: 290px;
        border-bottom: 1px solid $color-gray-light-alt;
        overflow: hidden;
        float: $ldirection;
        @include breakpoint($small-down) {
          float: none;
          width: 100%;
          min-height: 0;
          @include swap_direction(padding, 1.9% 1.7%);
          &.expanded .service-head .icon {
            background: url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/services/services_mobile_title_expanded_arrow.gif')
              no-repeat;
          }
          .service-head {
            position: relative;
            width: 100%;
            height: 38px;
            overflow: hidden;
            cursor: pointer;
            /* border-bottom: 1px solid $color-gray-light-alt; */

            .service-title {
              display: block;
              float: $ldirection;
              width: 85%;
              font-size: 12px;
              font-family: $body-font-bold;
              text-transform: uppercase;
              @include swap_direction(padding, 0);
              margin-top: 6px;
            }
            .icon {
              display: block;
              width: 20px;
              height: 32px;
              float: $rdirection;
              @include swap_direction(padding, 0);
              @include swap_direction(margin, 0);
              background: url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/services/services_mobile_title_arrow.gif')
                no-repeat;
            }
          }
        }
        .service-image {
          display: block;
          float: $ldirection;
          width: 41%;
          border: 1px solid $color-gray-light-alt;
          @include breakpoint($small-down) {
            width: 45.35%;
          }
        }
        .service-details {
          display: block;
          float: $ldirection;
          width: 53%; /* 47% */
          text-align: $ldirection;
          overflow: hidden;
          margin-#{$ldirection}: 20px;
          @include breakpoint($small-down) {
            width: 50.1%;
            margin-#{$ldirection}: 4.5%;
          }
          h3 {
            margin: 0;
            text-transform: uppercase;
            line-height: 1.1;
            &.service-title {
              font-family: $body-font-bold;
              letter-spacing: 0.2em;
              margin-top: -5px;
              line-height: 1.5em;
              @include breakpoint($small-down) {
                font-size: 12px;
                margin-top: -2px;
                letter-spacing: 0.19em;
                line-height: 1.2em;
              }
            }
          }
          .lesson_description {
            p {
              margin-top: 0;
              margin-bottom: 0;
              line-height: 1.72em;
              @include breakpoint($small-down) {
                font-size: 12px;
                line-height: 1.3em;
                letter-spacing: 0.06em;
              }
            }
          }
        }
        .selection-bar {
          position: absolute;
          display: block;
          width: 93.6%;
          #{$ldirection}: 3.4%;
          bottom: 6.4%;
          clear: both;
          padding-top: 19px;
          overflow: hidden;
          letter-spacing: 0.23em;
          @include breakpoint($small-down) {
            position: relative;
            #{$ldirection}: auto;
            bottom: auto;
            width: auto;
          }
          > div {
            display: block;
            float: $ldirection;
            line-height: 1;
            text-transform: uppercase;
            text-align: center;
            @include swap_direction(padding, 11px 0);
            @include swap_direction(margin, 0);
            font-family: $body-font-bold;
            @include breakpoint($small-down) {
              font-size: 10px;
              letter-spacing: 0.2em;
              @include swap_direction(padding, 6px 0);
            }
          }
          .selection-time {
            width: 41%;
            border: 1px solid $color-gray-light-alt;
            background-color: $color-gray-light-alt;
            text-transform: uppercase;
            @include breakpoint($small-down) {
              width: 50%;
            }
          }
          .selection-selector {
            border: 1px solid $color-black;
            background-color: $color-white;
            width: 58.7%;
            text-transform: uppercase;
            cursor: pointer;
            .icon {
              display: none;
              position: relative;
              top: -2px;
              margin-#{$rdirection}: 2px;
              width: 17px;
              height: 12px;
              background: url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/services/service_selected_check.gif') no-repeat;
              @include breakpoint($small-down) {
                display: none;
              }
            }
            @include breakpoint($small-down) {
              width: 50%;
            }
          }
          .selection-selector.selected {
            background-color: $color-black;
            color: $color-white;
            .icon {
              display: inline-block;
              @include breakpoint($small-down) {
                display: none;
              }
            }
          }
        }
      }
    }
    .appointment-select {
      width: auto;
      @include swap_direction(margin, 0 auto);
      overflow: hidden;
      .appointments-container {
        .booking-step {
          margin-bottom: 22px;
          text-align: $ldirection;
          &.step2 {
            padding: 0 3.6%;
            margin-bottom: 15px;
          }
          &.step3 {
            @include swap_direction(padding, 0 3.6%);
          }
        }
        .step-header {
          text-align: $ldirection;
          @include swap_direction(padding, 21px 0 18px);
          @include swap_direction(margin, 0);
          border-bottom: 1px solid $color-gray-light-alt;
          font-size: 18px;
          letter-spacing: 0.1em;
          @include breakpoint($small-down) {
            letter-spacing: 1px;
          }
        }
        .step1 {
          .step-header {
            @include swap_direction(margin, 0 3.6% 12px);
            @include breakpoint($small-down) {
              @include swap_direction(margin, 0 0 2px 10px);
            }
          }
        }
        .services {
          width: 100%;
          overflow: hidden;
          @include swap_direction(padding, 0 1.8%);
          @include breakpoint($small-down) {
            @include swap_direction(padding, 2.5% 3.6%);
            margin-top: 5px;
          }
          h4 {
            font-family: $body-font-bold;
            text-transform: uppercase;
            font-size: 16px;
            padding-bottom: 18px;
            letter-spacing: 0.2em;
            @include breakpoint($small-down) {
              letter-spacing: 1px;
            }
          }
        }
        .service {
          display: block;
          width: 50%;
          @include swap_direction(padding, 2.5% 1.8%);
          overflow: hidden;
          float: $ldirection;
          @include breakpoint($small-down) {
            width: auto;
            float: none;
            @include swap_direction(padding, 1.8% 0);
          }
          .service-head {
            margin: 0;
            text-transform: uppercase;
            line-height: 1.1;
            font-family: $body-font-bold;
            font-size: 16px;
            letter-spacing: 0.2em;
            @include breakpoint($small-down) {
              text-transform: none;
              font-family: $body-font;
              font-size: 14px;
              letter-spacing: 1px;
            }
          }
          .selection-bar {
            display: block;
            clear: both;
            padding-top: 18px;
            overflow: hidden;
            @include breakpoint($small-down) {
              display: none;
            }
            > div {
              display: block;
              float: $ldirection;
              line-height: 1;
              text-transform: uppercase;
              text-align: center;
              @include swap_direction(padding, 11px 0);
              @include swap_direction(margin, 0);
              font-family: $body-font-bold;
              @include breakpoint($tablet) {
                letter-spacing: 0.25em;
              }
            }
            .selection-time {
              width: 41%;
              border: 1px solid $color-gray-light-alt;
              background-color: $color-gray-light-alt;
              text-transform: uppercase;
            }
            .selection-selector {
              border: 1px solid $color-black;
              background-color: $color-white;
              width: 59%;
              text-transform: uppercase;
              .icon {
                display: none;
                position: relative;
                top: -2px;
                margin-#{$rdirection}: 2px;
                width: 17px;
                height: 12px;
                background: url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/services/service_selected_check.gif') no-repeat;
              }
            }
            .selection-selector.selected {
              background-color: $color-black;
              color: $color-white;
              .icon {
                display: inline-block;
              }
            }
          }
        }
        #change-services-link,
        #read-all-artist-link,
        #appt-book-sign-in-link,
        .start-over {
          text-transform: uppercase;
          font-family: $body-font-bold;
          padding-bottom: 5px;
          text-decoration: none;
          border-bottom: 1px solid $color-pink;
          font-size: 14px;
          letter-spacing: 0.23em;
          @include breakpoint($small-down) {
            font-size: 12px;
            padding-bottom: 0;
          }
        }
        #change-services-link {
          margin: 0 3.6%;
        }
        #read-all-artist-link {
          margin-#{$ldirection}: 51.8%;
        }
        .selects-container {
          overflow: hidden;
          width: 100%;
          margin-top: 18px;
          margin-bottom: 3px;
          @include swap_direction(padding, 0 1.8%);
          .select-container {
            float: $ldirection;
            text-align: $ldirection;
            @include swap_direction(padding, 2.5% 1.8%);
            width: 50%;
            @include breakpoint($small-down) {
              width: auto;
              float: none;
              display: block;
              select {
                width: 100%;
              }
            }
            h4 {
              font-family: $body-font-bold;
              text-transform: uppercase;
              font-size: 16px;
              padding-bottom: 18px;
              letter-spacing: 0.2em;
              @include breakpoint($small-down) {
                letter-spacing: 1px;
              }
            }
          }
        }
        .appointment-select {
          .calendar-head {
            position: relative;
            text-align: center;
            height: 73px;
            @include swap_direction(margin, 0);
            @include breakpoint($small-down) {
              text-align: $ldirection;
              height: auto;
              @include swap_direction(margin, 20px 0);
              font-family: $body-font-bold;
              font-size: 14px;
              span {
                font-family: $body-font;
              }
            }
            .cal-head-content {
              position: relative;
              display: inline-block;
              overflow: hidden;
              margin-top: 22px;
              > * {
                float: $ldirection;
              }
              .cal-copy {
                position: relative;
                font-family: $body-font-bold;
                font-size: 18px;
                text-transform: uppercase;
                @include swap_direction(padding, 2px 33px);
                letter-spacing: 0.2em;
                @include breakpoint($small-down) {
                  letter-spacing: 1px;
                }
              }
              .cal-controls {
                width: 25px;
                height: 25px;
                cursor: pointer;
                overflow: hidden; // for img replacement
                text-indent: 100%; // for img replacement
                white-space: nowrap; // for img replacement
              }
              .cal-controls.next {
                background: transparent
                  url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/appointments/cal_arrow_right.png')
                  no-repeat;
              }
              .cal-controls.previous {
                background: transparent
                  url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/appointments/cal_arrow_left.png')
                  no-repeat;
              }
            }
            .date-picker {
              position: absolute;
              #{$rdirection}: 0;
              top: 0;
              background: transparent
                url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/appointments/cal_date_picker_icon.png')
                no-repeat;
              width: 72px;
              height: 73px;
              cursor: pointer;
              overflow: hidden; // for img replacement
              text-indent: 100%; // for img replacement
              white-space: nowrap; // for img replacement
            }
          }
          @include breakpoint($small-down) {
            .date-picker {
              width: 100%;
              margin-bottom: 13px;
              .pikaday-container {
                @include swap_direction(padding, 0);
                .pika-single {
                  z-index: 0;
                  border: none;
                  font-family: $body-font-bold;
                  .pika-lendar {
                    float: none;
                    @include swap_direction(margin, 0);
                    width: 100%;
                    .pika-title {
                      font-size: 16px;
                      margin-top: 10px;
                      .pika-label {
                        z-index: 0;
                        &:last-child {
                          display: none;
                        }
                        .pika-select {
                          z-index: 0;
                          display: none;
                        }
                      }
                      .pika-prev,
                      .pika-next {
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        overflow: hidden; // for img replacement
                        text-indent: 100%; // for img replacement
                        white-space: nowrap; // for img replacement
                      }
                      .pika-prev {
                        background: transparent
                          url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/appointments/cal_arrow_left.png') no-repeat;
                      }
                      .pika-next {
                        background: transparent
                          url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/appointments/cal_arrow_right.png') no-repeat;
                      }
                    }
                    table.pika-table {
                      border-collapse: collapse;
                      border-spacing: 0;
                      td,
                      th {
                        border: none;
                        background-color: transparent;
                        height: 33px;
                        @include swap_direction(padding, 0);
                      }
                      th {
                        abbr,
                        abbr[title] {
                          border: none;
                          cursor: default;
                        }
                      }
                      td {
                        .pika-button {
                          background-color: $color-white;
                          color: $color-black;
                          border-radius: 0;
                          box-shadow: none;
                          text-align: center;
                          font-size: 14px;
                          font-family: $body-font-bold;
                          height: 33px;
                        }
                        &.is-selected .pika-button {
                          background-color: $color-black;
                          color: $color-white;
                          &:hover {
                            background-color: $color-black;
                            color: $color-white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          table {
            text-align: center;
            border: 1px solid $color-gray;
            @include swap_direction(margin, 0);
            @include breakpoint($small-down) {
              border: none;
              border-collapse: separate;
              border-spacing: 6px;
            }
            th {
              background-color: $color-gray;
              border: none;
              text-align: center;
              font-size: 16px;
              @include swap_direction(padding, 0.55em 0);
              &.hilite {
                background-color: $color-black;
                color: $color-white;
              }
            }
            td {
              border: 1px solid $color-gray-light-alt;
              text-align: center;
              color: $color-gray;
              cursor: default;
              font-size: 18px;
              @include swap_direction(padding, 0.46em 0);
            }
            td.enabled {
              background-color: $color-white;
              color: $color-black;
              cursor: pointer;
              font-family: $body-font-bold;
              &:hover {
                color: $color-white;
                background-color: $color-black;
              }
              @include breakpoint($small-down) {
                background-color: $color-black;
                color: $color-white;
              }
            }
            td.selected {
              background-color: $color-pink;
              color: $color-white;
              font-family: $body-font-bold;
              &:hover {
                color: $color-white;
                background-color: $color-pink;
              }
            }
          }
        }
        .error-messages {
          display: none;
          .error-message {
            display: none;
            @include breakpoint($small-down) {
              color: #ff0000;
            }
          }
        }
        .sign-in-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          @include swap_direction(margin, 37px 0 0 0);
          letter-spacing: 0.1em;
          @include breakpoint($small-down) {
            letter-spacing: 1px;
          }
          h4 {
            margin-bottom: 28px;
            font-size: 18px;
          }
          .sign-in-form-container {
            display: none;
            position: relative;
            overflow: hidden;
            input {
              display: block;
              width: 48.2%;
              margin-bottom: 20px;
              height: 38px;
              font-size: 18px;
              font-family: $body-font;
              &.login__email {
                float: $ldirection;
                @include breakpoint($small-down) {
                  width: 100%;
                  float: none;
                }
              }
              &.login__password {
                float: $rdirection;
                @include breakpoint($small-down) {
                  width: 100%;
                  float: none;
                }
              }
              &.login__submit {
                display: inline-block;
                width: auto;
                clear: both;
                float: $rdirection;
                font-size: 14px;
                font-family: $body-font-bold;
                @include breakpoint($small-down) {
                  float: none;
                }
              }
            }
          }
        }
        .book-appt-container {
          display: block;
          overflow: hidden;
          width: 100%;
          height: auto;
          @include swap_direction(margin, 12px 0 23px);
          letter-spacing: 0.1em;
          @include breakpoint($small-down) {
            letter-spacing: 1px;
          }
          h4 {
            margin-bottom: 17px;
            font-size: 18px;
            letter-spacing: 0.1em;
            @include breakpoint($small-down) {
              letter-spacing: 1px;
            }
          }
          input,
          fieldset {
            display: block;
            width: 48.2%;
            margin-bottom: 20px;
            font-size: 18px;
            font-family: $body-font;
            height: 38px;
            @include breakpoint($small-down) {
              width: 100%;
              float: none;
            }
            &.appt-book-first-name,
            &.appt-book-email {
              float: $ldirection;
              @include breakpoint($small-down) {
                width: 100%;
                float: none;
              }
            }
            &.appt-book-last-name,
            &.mobile-phone-fieldset {
              float: $rdirection;
              @include breakpoint($small-down) {
                width: 100%;
                float: none;
              }
            }
            &.mobile-phone-fieldset {
              border: none;
              @include swap_direction(padding, 0);
              @include swap_direction(margin, 0);
              overflow: hidden;
            }
            &.appt-book-mobile-prefix,
            &.appt-book-mobile {
              float: $ldirection;
              @include swap_direction(margin, 0);
            }
            &.appt-book-mobile-prefix {
              width: 20%;
              border-#{$rdirection}: none;
              @include breakpoint($small-down) {
                width: 25%;
              }
            }
            &.appt-book-mobile {
              width: 80%;
              @include breakpoint($small-down) {
                width: 75%;
              }
            }
          }
          .note-head {
            clear: both;
            padding-top: 7px;
            padding-bottom: 3px;
          }
          .appt-book-note {
            width: 100%;
            display: block;
            height: 149px;
            margin-bottom: 16px;
            font-family: $body-font;
            font-size: 18px;
            letter-spacing: 0.1em;
            @include breakpoint($small-down) {
              letter-spacing: 1px;
            }
          }
          .registration__email-list,
          .registration__terms,
          .registration__oab-email-promotions,
          .registration__sms-list {
            font-family: $body-font;
            width: 48.2%;
            float: $ldirection;
            margin-top: auto;
            padding-top: 1em;
            &:before {
              @include swap_direction(margin, 0);
            }
            @include breakpoint($small-down) {
              width: auto;
              float: none;
              display: block;
              margin-bottom: 18px;
            }
          }
          .registration__sms-list {
            float: $rdirection;
            @include breakpoint($small-down) {
              width: auto;
              float: none;
            }
          }
        }
        .book-appt-container.no-last-name {
          input.appt-book-first-name {
            width: 100%;
          }
          input.appt-book-last-name {
            display: none;
          }
        }
        .confirm-container {
          display: block;
          overflow: hidden;
          width: 48.2%;
          height: auto;
          @include swap_direction(margin, 18px 0);
          float: $rdirection;
          @include breakpoint($small-down) {
            width: auto;
            float: none;
            text-align: $ldirection;
          }
          .total-time {
            font-family: $body-font-bold;
            font-size: 18px;
            float: $ldirection;
            width: 49%;
            @include swap_direction(padding, 10px 0);
            @include breakpoint($small-down) {
              width: auto;
              float: none;
            }
          }
          .appt-book-book-submit {
            float: $rdirection;
            letter-spacing: 0.25em;
            @include swap_direction(padding, 0 18px);
            height: 38px;
            background-color: $color-pink;
            cursor: pointer;
            &.disabled {
              background-color: $color-gray;
              color: $color-black;
              cursor: default;
            }
            @include breakpoint($small-down) {
              float: none;
            }
          }
          .start-over {
            text-align: $rdirection;
            float: $rdirection;
            display: block;
            margin-top: 24px;
            text-transform: uppercase;
            color: $color-black;
            border-color: $color-black;
            letter-spacing: 0.25em;
            @include breakpoint($small-down) {
              display: inline-block;
              width: auto;
              float: none;
              text-align: $ldirection;
            }
          }
        }
      }
    }
    .page-header {
      &__title {
        font-family: $body-font-bold;
        font-size: 39px;
        font-weight: bold;
        letter-spacing: 7px;
        padding-#{$ldirection}: 90px;
        text-align: $ldirection;
        text-transform: uppercase;
      }
      &__subtitle {
        padding-#{$ldirection}: 90px;
        text-align: $ldirection;
        p {
          font-family: $body-font;
          font-size: 24px;
          line-height: 1.3;
        }
      }
    }
    .confirmation {
      width: 100%;
      position: relative;
      .appt-book-content-header {
        @include swap_direction(margin, 40px 0);
        p {
          width: 56%;
          @include swap_direction(margin, 15px auto);
          @include breakpoint($small-down) {
            width: 100%;
            text-align: $ldirection;
          }
        }
      }
      .confirmation-container {
        position: relative;
        width: 100%;
        border-top: 1px solid #d0d0d0;
        overflow: hidden;
        @include breakpoint($small-down) {
          border-top: none;
        }
        > div {
          float: $ldirection;
          width: 50%;
          min-height: 220px;
          @include swap_direction(padding, 35px 10px 0);
          text-align: center;
          border-bottom: 1px solid #d0d0d0;
          overflow: hidden;
          @include breakpoint($small-down) {
            float: none;
            text-align: $ldirection;
            width: 100%;
            border: none;
            min-height: 0;
            @include swap_direction(padding, 0);
            @include swap_direction(margin, 0 0 35px);
          }
          h4 {
            font-family: $body-font-bold;
            font-size: 18px;
            letter-spacing: 4px;
            @include swap_direction(margin, 5px);
            text-transform: uppercase;
            text-align: center;
            @include breakpoint($small-down) {
              text-align: $ldirection;
              border-bottom: 1px solid $color-gray-light;
            }
          }
          span {
            display: block;
            text-align: center;
            @include swap_direction(margin, 5px);
            @include breakpoint($small-down) {
              text-align: $ldirection;
            }
          }
        }
        .your-lessons,
        .date-time {
          border-#{$rdirection}: 1px solid #d0d0d0;
          @include breakpoint($small-down) {
            border-#{$rdirection}: none;
          }
        }
        .date-time,
        .artist {
          padding-top: 34px;
          @include breakpoint($small-down) {
            padding-top: 0;
          }
        }
        .date-time {
          .appt-date-cal {
            background: transparent
              url('https://www.bobbibrowncosmetics.ca/media/export/cms/makeup_services/appointment_booking/appointments/cal_date_picker_icon.png')
              no-repeat
              22.5em
              0.5em;
            .add-cal-link {
              border-bottom: 1px solid $color-black;
              display: inline-block;
              padding-bottom: 2px;
              margin-bottom: 5px;
              cursor: pointer;
            }
            .cals-container {
              width: 150px;
              display: none;
              position: absolute;
              border: 1px solid $color-black;
              .appt-book-link {
                border: none;
                display: block;
                @include swap_direction(padding, 4px 15px 0);
                &:first-child {
                  padding-top: 5px;
                }
                &:last-child {
                  padding-bottom: 5px;
                }
              }
              &.expanded {
                display: block;
                #{$ldirection}: 18%;
              }
            }
            .appt-book-link {
              font-size: 11px;
              line-height: 18.4px;
              letter-spacing: 0;
              border-color: 1px solid $color-black;
            }
          }
        }
        .location {
          a {
            border-bottom: 1px solid #ff4661;
            font-family: $body-font-bold;
            padding-bottom: 5px;
            font-size: 14px;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 0.23em;
            @include breakpoint($small-down) {
              font-size: 12px;
              padding-bottom: 0;
            }
          }
        }
      }
    }
    .my-appointments {
      width: 100%;
      position: relative;
      .appt-book-content-header {
        @include swap_direction(margin, 36px 0 28px);
        p {
          width: 56%;
          @include swap_direction(margin, 18px auto);
          @include breakpoint($small-down) {
            width: 100%;
            text-align: $ldirection;
          }
        }
      }
      .appointments-container {
        position: relative;
        width: auto;
        overflow: hidden;
        @include swap_direction(margin, 0 3.6% 0);
        @include breakpoint($small-down) {
          @include swap_direction(margin, 0);
        }
        .mobile-header {
          display: none;
          @include breakpoint($small-down) {
            font-family: $body-font-bold;
            text-transform: uppercase;
            display: block;
            border-top: 1px solid $color-gray-light;
            border-bottom: 1px solid $color-gray-light;
            width: 100%;
            @include swap_direction(padding, 15px 0);
            @include swap_direction(margin, 5px 0 23px);
          }
        }
        &.past {
          background-color: $color-gray-light-alt;
          height: auto;
          overflow: hidden;
          border-top: 1px solid $color-gray-light;
          border-bottom: 1px solid $color-gray-light;
          .past-appt-head {
            width: 100%;
            position: relative;
            text-align: center;
            border-bottom: 1px solid $color-gray-light;
            @include breakpoint($small-down) {
              margin-bottom: 35px;
            }
            a.view-link {
              font-family: $body-font-bold;
              font-size: 14px;
              text-transform: uppercase;
              color: $color-black;
              text-align: center;
              display: block;
              height: 84px;
              @include swap_direction(padding, 27px 0 0);
              border-bottom: 1px solid $color-gray-light;
              letter-spacing: 0.25em;
              position: relative;
              @include breakpoint($small-down) {
                letter-spacing: 1px;
                height: 54px;
                @include swap_direction(padding, 13px 0 0);
              }
              &:before {
                content: '';
                position: absolute;
                width: 255px;
                margin-top: 25px;
                border-bottom: 1px solid $color-black;
                margin-#{$ldirection}: -129px;
                #{$ldirection}: 50%;
                @include breakpoint($small-down) {
                  border-bottom: none;
                }
              }
            }
            .section-header {
              @include swap_direction(margin, 28px 0 29px);
              text-align: center;
              font-size: 16px;
              letter-spacing: 0.1em;
              @include breakpoint($small-down) {
                letter-spacing: 0.2em;
                font-size: 12px;
                @include swap_direction(margin, 0);
                @include swap_direction(padding, 0);
                &:before {
                  background: transparent;
                }
              }
              .section-header .inner,
              .section-header__inner {
                background-color: $color-gray-light-alt;
                @include swap_direction(padding, 0 14px 0 18px);
                @include breakpoint($small-down) {
                  letter-spacing: 0.2em;
                  font-size: 12px;
                  @include swap_direction(margin, 20px 0);
                  @include swap_direction(padding, 0 5px);
                }
              }
            }
          }
          .appointment-details {
            @include swap_direction(margin, 0);
            &:last-child {
              .date-time,
              .artist {
                border-bottom: 1px solid #d0d0d0;
                @include breakpoint($small-down) {
                  border-bottom: none;
                }
              }
            }
            .date-time,
            .artist {
              border-bottom: 2px solid $color-black;
              @include breakpoint($small-down) {
                border-bottom: none;
              }
            }
          }
          .appointment-container {
            @include breakpoint($small-down) {
              @include swap_direction(padding, 0 5px);
            }
          }
        }
      }
      .appointment-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-bottom: 0;
        .appointment-details {
          position: relative;
          width: 100%;
          @include swap_direction(margin, 40px 0);
          overflow: hidden;
          font-size: 18px;
          line-height: 22px;
          @include breakpoint($small-down) {
            font-size: 14px;
            line-height: 19px;
          }
          > div {
            float: $ldirection;
            width: 50%;
            min-height: 230px;
            @include swap_direction(padding, 33px 10px 0);
            text-align: center;
            border-bottom: 1px solid #d0d0d0;
            overflow: hidden;
            @include breakpoint($small-down) {
              float: none;
              text-align: $ldirection;
              width: 100%;
              border: none;
              min-height: 0;
              @include swap_direction(padding, 0);
              @include swap_direction(margin, 0 0 35px);
            }
            h4 {
              font-family: $body-font-bold;
              font-size: 18px;
              letter-spacing: 4px;
              margin: 1px 5px 10px;
              text-transform: uppercase;
              text-align: center;
              @include breakpoint($small-down) {
                text-align: $ldirection;
                border-bottom: 1px solid $color-gray-light;
              }
            }
            span {
              display: block;
              text-align: center;
              @include swap_direction(margin, 5px);
              @include breakpoint($small-down) {
                text-align: $ldirection;
              }
            }
          }
          .your-lessons,
          .location {
            border-top: 1px solid #d0d0d0;
            @include breakpoint($small-down) {
              border-top: none;
            }
          }
          .your-lessons,
          .date-time {
            border-#{$rdirection}: 1px solid #d0d0d0;
            @include breakpoint($small-down) {
              border-#{$rdirection}: none;
            }
          }
          .date-time,
          .artist {
            padding-top: 74px;
            @include breakpoint($small-down) {
              padding-top: 0;
            }
          }
          .location {
            a {
              border-bottom: 1px solid #ff4661;
              font-family: $body-font-bold;
              padding-bottom: 5px;
              font-size: 14px;
              text-decoration: none;
              text-transform: uppercase;
              letter-spacing: 0.23em;
              @include breakpoint($small-down) {
                font-size: 12px;
                padding-bottom: 0;
              }
            }
          }
        }
        &:last-child {
          .appointment-details {
            margin-bottom: 0;
          }
        }
      }
      .appt-booking-links {
        @include swap_direction(margin, 26px 0 30px 0);
        h4 {
          font-family: $body-font;
          font-size: 18px;
          @include swap_direction(margin, 0 0 28px);
          letter-spacing: 0.1em;
        }
        a.button {
          background-color: $color-pink;
          @include breakpoint($tablet) {
            letter-spacing: 0.25em;
            @include swap_direction(padding, 0.8em 0.45em 0.95em 0.7em);
          }
        }
      }
      .appointments-buttons {
        margin-bottom: 36px;
        @include breakpoint($small-down) {
          margin-bottom: 23px;
        }
        .change-appt-btn {
          margin-#{$rdirection}: 36px;
          letter-spacing: 0.25em;
          @include swap_direction(padding, 0.85em 0.6em);
          background-color: $color-pink;
          &.mobile {
            display: none;
          }
          @include breakpoint($small-down) {
            letter-spacing: 1px;
            display: none;
            &.mobile {
              display: inline-block;
            }
          }
        }
        a.cancel-appt-btn {
          color: $color-black;
          border-color: $color-black;
        }
      }
      #appt-book-my-appointments-no-appts {
        .button-container {
          margin-top: 80px;
          @include breakpoint($small-down) {
            margin-top: 40px;
          }
        }
      }
    }
    .appointment-cancellation {
      width: 100%;
      position: relative;
      & .cancel-content,
      & .error-content {
        display: none;
      }
      .button-container {
        display: block;
        margin-top: 40px;
        text-align: center;
      }
    }
  }
  .appt-book-overlay {
    display: none;
    position: fixed;
    top: 0;
    #{$ldirection}: 0;
    bottom: 0;
    #{$rdirection}: 0;
    background-color: #ffffff; //fallback
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
    .overlay-content-container {
      position: absolute;
      display: block;
      @include swap_direction(padding, 32px 25px);
      text-align: center;
      background-color: $color-white;
      border: 1px solid $color-gray-light-alt;
      z-index: 1;
      width: 428px;
      height: auto;
      min-height: 185px;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
      @include breakpoint($small-down) {
        width: 300px;
        min-height: 232px;
        margin-#{$ldirection}: -150px;
      }
      .overlay-close {
        position: absolute;
        display: block;
        #{$rdirection}: 0;
        top: 0;
        background: transparent url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/common/overlay_close_btn.gif') no-repeat;
        width: 37px;
        height: 32px;
        cursor: pointer;
        z-index: 3;
        overflow: hidden; // for img replacement
        text-indent: 100%; // for img replacement
        white-space: nowrap; // for img replacement
      }
      .overlay-content {
        display: block;
        position: relative;
        text-align: center;
        h4 {
          font-family: $body-font-bold;
          text-transform: uppercase;
          margin-bottom: 12px;
          letter-spacing: 0.205em;
          margin-top: 4px;
          @include breakpoint($small-down) {
            font-size: 24px;
            letter-spacing: 0.11em;
            line-height: 1.1em;
            margin-bottom: 8px;
            margin-top: 13px;
          }
        }
        p {
          line-height: 1.75;
          margin-top: 19px;
          @include breakpoint($small-down) {
            @include swap_direction(margin, 0 auto);
            line-height: 1.3em;
            font-size: 12px;
            width: 82%;
          }
        }
      }
    }
    &.appt-book-services-overlay .overlay-content-container,
    &.appt-book-book-conflict-overlay .overlay-content-container {
      width: 428px;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
      @include breakpoint($small-down) {
        width: 300px;
        height: 232px;
        margin-#{$ldirection}: -150px;
      }
    }
    &.appt-book-datepicker-overlay {
      z-index: 1000;
      .overlay-content-container {
        width: 950px;
        top: 100px;
        #{$ldirection}: 50%;
        margin-#{$ldirection}: -475px;
        @include breakpoint($tablet-down) {
          top: 60px;
          padding-top: 65px;
          padding-bottom: 38px;
        }
        .pika-single {
          color: $color-black;
          border: none;
          .pika-lendar {
            @include breakpoint($tablet) {
              border-#{$rdirection}: 1px solid $color-gray-light-alt;
              width: 50%;
              padding-#{$rdirection}: 3%;
              padding-#{$ldirection}: 0.5%;
              margin: 0;
              &:last-child {
                border: none;
                padding-#{$rdirection}: 0.5%;
                padding-#{$ldirection}: 3%;
              }
            }
          }
        }
        .pika-title {
          @include swap_direction(padding, 0 10px);
          button {
            &:hover {
              background-color: transparent;
            }
          }
        }
        .pika-label {
          font-family: $body-font-bold;
          font-size: 24px;
          letter-spacing: 0.25em;
          @include breakpoint($small-down) {
            font-size: 14px;
            letter-spacing: 1px;
          }
        }
        .pika-table {
          @include breakpoint($tablet) {
            margin-top: 27px;
          }
          th {
            color: $color-black;
            border-bottom: none;
            font-family: $body-font-bold;
            @include breakpoint($tablet) {
              font-size: 18px;
              @include swap_direction(padding, 0.7em 0 1em);
            }
          }
          abbr[title],
          abbr {
            border: none;
            text-decoration: none;
            cursor: default;
          }
          td {
            color: $color-black;
            border-bottom: none;
            font-family: $body-font;
            @include breakpoint($tablet) {
              font-size: 24px;
              @include swap_direction(padding, 0.47em 0);
            }
          }
        }
        .pika-button {
          background: #ffffff;
          color: $color-black;
          border-radius: 0;
          font-family: $body-font;
          @include breakpoint($tablet) {
            font-size: 24px;
            @include swap_direction(padding, 25px 20px);
            line-height: 5px;
          }
          &:hover {
            background-color: $color-black;
            color: $color-white;
            border-radius: 0;
          }
        }
        .pika-prev,
        .is-rtl .pika-next {
          background: transparent
            url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/appointments/cal_arrow_left.png')
            no-repeat;
        }
        .pika-next,
        .is-rtl .pika-prev {
          background: transparent
            url('https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/appointments/cal_arrow_right.png')
            no-repeat;
        }
      }
    }
    &.my-appointments-overlay .overlay-content-container {
      width: 428px;
      top: 50%;
      #{$ldirection}: 50%;
      margin-top: -95px;
      margin-#{$ldirection}: -214px;
      #confirm-cancel {
        p {
          font-family: $body-font-bold;
          text-transform: uppercase;
          letter-spacing: 0.2em;
          margin-top: 1px;
          @include breakpoint($small-down) {
            br {
              display: none;
            }
          }
        }
        .button {
          width: 152px;
          height: 38px;
          @include breakpoint($small-down) {
            display: block;
            @include swap_direction(margin, 10px auto);
          }
          &.yes-btn {
            margin-#{$rdirection}: 36px;
            @include breakpoint($small-down) {
              margin-#{$rdirection}: auto;
            }
          }
        }
      }
      @include breakpoint($small-down) {
        width: 300px;
        min-height: 0;
        margin-#{$ldirection}: -150px;
        p {
          letter-spacing: 1px;
        }
      }
      .canceled {
        h4 {
          @include swap_direction(margin, 1px auto 12px);
          line-height: 24px;
          width: 80%;
          @include breakpoint($small-down) {
            width: auto;
            font-size: 14px;
          }
        }
        p {
          margin-top: 17px;
          margin-bottom: 23px;
          span {
            display: block;
            text-align: center;
            margin-bottom: 5px;
          }
        }
        .book-new-btn {
          @include swap_direction(padding, 0.86em 1.1em 0.86em 1.4em);
          letter-spacing: 0.25em;
          margin-bottom: 7px;
          background-color: $color-pink;
          @include breakpoint($small-down) {
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  .appt-book {
    &-page-header-content,
    &-content-header {
      .section-header {
        margin-top: 3px;
        margin-bottom: 3px;
        @include breakpoint($small-down) {
          width: 100%;
          font-size: 20px;
          text-align: center;
        }
        &__inner {
          font-size: 16px;
        }
      }
    }
  }
  .no-rgba .appt-book-overlay {
    background: transparent url(https://www.bobbibrowncosmetics.ca/media/export/cms/appointment_booking/common/overlay_tint.png) repeat;
  }
}

@if $virtual-appointment {
  .appt-book {
    .appointment-booking-main-collection {
      &__header {
        &-picture {
          img {
            @include breakpoint($medium-portrait-only) {
              height: 25vh;
            }
            @include breakpoint($medium-landscape-only) {
              height: 40vh;
            }
          }
        }
      }
      &__section {
        .appointment-booking-review {
          &-virtual {
            &__details {
              width: 100%;
            }
          }
        }
      }
    }
    .virtual__appointment--btn {
      padding: 0 50px;
      margin: 10px 0;
      font-family: $body-font-bold;
      letter-spacing: 0.6px;
      font-size: 11px;
      height: 34px;
      &:hover,
      &:active,
      &:visited,
      &:focus {
        background: $color-black;
      }
    }
    .appointment-booking-location {
      &__header {
        .virtual__appt {
          &__text {
            @include breakpoint($medium-up) {
              font-weight: bold;
            }
          }
          &__booking {
            &__subheader {
              @include breakpoint($medium-up) {
                margin: 36px 0 12px;
              }
              margin: 20px 0 5px;
            }
            &--btn {
              @include breakpoint($medium-up) {
                padding: 0 70px;
                height: 38px;
              }
              padding: 0 60px;
              height: 42px;
            }
          }
        }
        .appt__booking__line-break {
          &--pc {
            @include breakpoint($medium-up) {
              display: block;
            }
          }
          &--mobile {
            @include breakpoint($medium-up) {
              display: inline;
            }
            display: block;
          }
        }
        .connector__text {
          @include breakpoint($medium-up) {
            margin-bottom: 30px;
          }
          font-family: $body-font;
          margin: 15px 0 20px;
        }
      }
    }
  }
}

.virtual-service-message {
  color: $color-red;
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
}
