.cart-products {
  .currency_type {
    @include hidden;
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .promos {
      .free_ship {
        height: 40px;
      }
    }
  }
}
