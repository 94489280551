.customer-service-menu {
  float: $ldirection;
  width: 28%;
  @include swap_direction(margin, 0 2% 0 0);
  @include swap_direction(padding, 0 20px);
  background-color: $color-gray-light-alt;
  @include breakpoint($small-down) {
    float: none;
    width: 100%;
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    background-color: $color-white;
  }
  .menu {
    &__item--lvl-1 {
      padding: 0.5em 0;
      border-bottom: 1px solid $color-gray;
      background-image: url('https://www.bobbibrowncosmetics.ca/media/images/service/customer-service-icon-arrow-closed.gif');
      background-position: $rdirection 1em;
      background-repeat: no-repeat;
      @include breakpoint($small-down) {
        @include swap_direction(padding, 0.5em 0 0.5em 40px);
        background: none;
      }
    }
    &__item--lvl-1.last {
      border-bottom: none;
      @include breakpoint($small-down) {
        border-bottom: 1px solid $color-gray;
      }
    }
    &__link {
      height: 2em;
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      color: $color-black;
      line-height: 2em;
      text-decoration: none;
    }
    &__link--lvl-1 {
      text-transform: uppercase;
      cursor: pointer;
      font-size: 18px;
      font-family: $bb-sans;
      line-height: 1;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
    }
    /* Hide all submenus at first */
    &--lvl-2 {
      display: none;
    }
    /* Styling for displaying an active submenu */
    &__item--lvl-1.active-submenu {
      background-image: url('https://www.bobbibrowncosmetics.ca/media/images/service/customer-service-icon-arrow-open.gif');
      .menu__link--lvl-1 {
        color: $color-pink;
      }
      .menu--lvl-2 {
        display: block;
        .menu__link--lvl-2:hover {
          color: $color-pink;
        }
      }
      @include breakpoint($small-down) {
        background: none;
      }
    }
  }
  a.menu__link--lvl-1 {
    display: block;
    width: 247px;
  }
}
