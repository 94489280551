#terms_chat a.customer-service-quick-info__link {
  margin-left: 60px;
  padding-top: 10px;
  span.icon {
    top: 1px;
  }
}
#live_chat,
#photo_chat,
#live_order_chat {
  position: absolute;
  bottom: 0;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  a {
    letter-spacing: 1px;
  }
}
.section-live-chat-landing {
  #live_chat,
  #photo_chat,
  #live_order_chat {
    position: static;
    a {
      position: static;
      color: $color-core-black;
      font-size: 12px;
      padding-top: 10px;
      display: block;
      text-decoration: underline;
      letter-spacing: 1px;
    }
  }
  .reach_out {
    .button--inverted {
      color: $color-core-black;
      font-size: 12px;
      text-decoration: underline;
      background: transparent;
      letter-spacing: 1px;
      font-family: $bbtext-regular;
    }
  }
}

.lp_survey_view_container {
  .lp_pages_area {
    .lp_radiobutton_wrapper {
      input[type='radio'] {
        display: none;
      }
    }
  }
}

.cart-product-preorder {
  float: left;
  font-size: 12px;
}

.product {
  &__inventory-status {
    .preorder-noshop {
      font-size: 12px;
      text-align: right;
      @include breakpoint($medium-up) {
        width: 195px;
        float: right;
      }
    }
  }
}

.mpp,
.spp,
.spp-mobile-page {
  .product__flag {
    display: inline-block;
  }
}

.spp,
.mpp {
  .product {
    .product__title {
      .product__flag {
        display: inline-block;
      }
    }
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

.device-mobile {
  .site-footer-contact #artist_chat_footer {
    .icon-livechat:before {
      left: 15px;
    }
  }
  .site-utils__nav .menu__link--lvl-1 {
    #artist_chat_link a.menu__link--lvl-1 {
      padding-left: 0;
    }
  }
  #footer_sticky_chat {
    min-height: 63px;
  }
}

.touch .device-mobile {
  #lpChat .lp_lpview_content select {
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    appearance: menulist;
  }
}

.spp-mobile-page {
  .product__inventory-status {
    .preorder-noshop {
      text-align: center;
    }
  }
  .shaded_details {
    .product__inventory-status {
      .preorder-noshop {
        text-align: left;
      }
    }
  }
}

:lang(fr) {
  .sticky-chat,
  .site-utils__item--live-chat,
  .site-utils__item--live-chat,
  .text-block_chat-now,
  .text-block_photo {
    display: none !important;
  }
  .text-block_photo {
    border-right: 1px solid #cccccc;
  }
  .text-block_email {
    border-bottom: 1px solid #cccccc;
  }
  .device-pc {
    .live-chat-landing .text-block_link a {
      width: 195px;
    }
  }
  .device-mobile {
    .livechat_content #live_chat_header,
    .chat_items {
      display: none;
    }
  }
  .mpp-live-chat-reviews {
    display: none;
  }
}
// CX-5843 - Birth year has to be removed from several pages
.site-email-sms-signup {
  &__birthday {
    text-align: left;
    &-label {
      fieldset & {
        .site-email-sms-signup__field & {
          width: 80%;
          @include breakpoint($medium-up) {
            width: 100px;
          }
        }
      }
    }
  }
  &__terms-conditions {
    margin-#{$ldirection}: 10px;
    @include breakpoint($medium-up) {
      font-size: 11px;
      margin-top: 0;
      line-height: 1.2;
    }
  }
  &__header {
    padding-top: 35px;
    @include breakpoint($medium-up) {
      padding-top: 0;
    }
    h3 {
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 1.2em;
      }
    }
    h1 {
      margin-bottom: 7px;
    }
  }
}

.popup-offer {
  &__sign-up-form {
    .selectbox {
      display: inline;
      float: none;
      &.select-year {
        display: none;
      }
    }
  }
}

.sign-in {
  &-component__birthday-program {
    .day-of-birth__collection-field {
      a {
        &.select-year {
          display: none !important;
        }
      }
    }
  }
}

.sign-birthday-info .optional-info__birthday-day {
  margin-right: 35%;
}

.optional-info {
  .birthday {
    .optional-info__item {
      @include swap_direction(padding, 10px 0 5px 10px);
      width: 32%;
      margin-#{$rdirection}: 10px;
    }
  }
}

.how-to-use__content {
  .disclaimer {
    display: block;
  }
}

.optanon-toggle-display,
.optanon-allow-all {
  &:focus {
    background: none;
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

#cookie_popover_container {
  display: none !important;
}

.esearch_results--pc {
  &.wrap {
    @include breakpoint($medium-up) {
      max-width: 1444px;
      .product-grid__content {
        max-width: inherit;
        .pr-category-snippet__total {
          display: block;
        }
      }
    }
  }
  &.esearch-results__section {
    @include breakpoint($medium-up) {
      max-width: 1024px;
    }
  }
}

.search-results__header--desktop {
  @include breakpoint($medium-up) {
    .search-form__results-count {
      line-height: 2.4;
    }
    .search-form__submit {
      margin: 0;
    }
  }
}

.search-results__header--desktop {
  @include breakpoint($medium-up) {
    .search-form__results-count {
      line-height: 2.4;
      #{$rdirection}: 180px;
    }
    input[type='submit'].search-form__submit {
      margin: 0;
    }
  }
}

.section-esearch {
  .product-grid__content {
    .p-w-r {
      .pr-snippet {
        .pr-category-snippet__total {
          display: table-cell;
        }
      }
    }
  }
}

.spp-olapic {
  .olapic {
    .olapic-slider {
      .olapic-slider-body {
        .olapic-nav-prev:before,
        .olapic-nav-next:before {
          top: 30%;
        }
      }
    }
  }
}

.site-footer {
  .site-email-signup {
    &__form {
      a {
        color: $color-white;
        display: inline;
        &:hover {
          color: $color-pink;
        }
      }
    }
  }
}
