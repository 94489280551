.viewcart {
  .checkout__sidebar {
    .card-details {
      .paypal-card,
      .afterpay-card {
        display: none;
      }
    }
  }
}
